import React from 'react'
import figmaIconWhite from '../../images/figma-icon-white.svg'
import { SignupBar } from './SignupBar'

export const FigmaSignupBar: React.FC = () => (
  <SignupBar
    title="Figma"
    description="Sign up and connect in under a minute"
    iconSrc={figmaIconWhite}
    url={process.env.SIGNUP_URL}
  />
)
