// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-integrations-asana-tsx": () => import("./../../../src/pages/integrations/asana.tsx" /* webpackChunkName: "component---src-pages-integrations-asana-tsx" */),
  "component---src-pages-integrations-figma-tsx": () => import("./../../../src/pages/integrations/figma.tsx" /* webpackChunkName: "component---src-pages-integrations-figma-tsx" */),
  "component---src-pages-integrations-github-js": () => import("./../../../src/pages/integrations/github.js" /* webpackChunkName: "component---src-pages-integrations-github-js" */),
  "component---src-pages-integrations-google-tsx": () => import("./../../../src/pages/integrations/google.tsx" /* webpackChunkName: "component---src-pages-integrations-google-tsx" */),
  "component---src-pages-integrations-jira-tsx": () => import("./../../../src/pages/integrations/jira.tsx" /* webpackChunkName: "component---src-pages-integrations-jira-tsx" */),
  "component---src-pages-integrations-js": () => import("./../../../src/pages/integrations.js" /* webpackChunkName: "component---src-pages-integrations-js" */),
  "component---src-pages-integrations-linear-tsx": () => import("./../../../src/pages/integrations/linear.tsx" /* webpackChunkName: "component---src-pages-integrations-linear-tsx" */),
  "component---src-pages-integrations-trello-tsx": () => import("./../../../src/pages/integrations/trello.tsx" /* webpackChunkName: "component---src-pages-integrations-trello-tsx" */),
  "component---src-pages-legal-privacy-and-cookies-policy-js": () => import("./../../../src/pages/legal/privacy-and-cookies-policy.js" /* webpackChunkName: "component---src-pages-legal-privacy-and-cookies-policy-js" */),
  "component---src-pages-legal-software-terms-and-conditions-js": () => import("./../../../src/pages/legal/software-terms-and-conditions.js" /* webpackChunkName: "component---src-pages-legal-software-terms-and-conditions-js" */),
  "component---src-pages-legal-website-terms-and-conditions-js": () => import("./../../../src/pages/legal/website-terms-and-conditions.js" /* webpackChunkName: "component---src-pages-legal-website-terms-and-conditions-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-release-notes-js": () => import("./../../../src/pages/release-notes.js" /* webpackChunkName: "component---src-pages-release-notes-js" */),
  "component---src-pages-values-js": () => import("./../../../src/pages/values.js" /* webpackChunkName: "component---src-pages-values-js" */),
  "component---src-templates-blog-post-template-js": () => import("./../../../src/templates/blogPostTemplate.js" /* webpackChunkName: "component---src-templates-blog-post-template-js" */),
  "component---src-templates-release-note-template-js": () => import("./../../../src/templates/releaseNoteTemplate.js" /* webpackChunkName: "component---src-templates-release-note-template-js" */)
}

