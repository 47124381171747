module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Qualdesk","short_name":"Qualdesk","start_url":"/","background_color":"#f8fafc","theme_color":"#f8fafc","display":"standalone","icon":"/workspace/apps/marketing-website/src/favicon.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"89acee3bf0f23d12c10616bbb9b4cbc1"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-T3NLVJ5","includeInDevelopment":true,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change"},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".md",".mdx"],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-better-embed-video","options":{"width":636,"related":false,"showInfo":false}},"@weknow/gatsby-remark-twitter",{"resolve":"gatsby-remark-images","options":{"linkImagesToOriginal":false,"maxWidth":730,"quality":90}},{"resolve":"gatsby-remark-copy-linked-files","options":{"ignoreFileExtensions":["png","jpg","jpeg","bmp","tiff"]}}],"defaultLayouts":{},"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/workspace/apps/marketing-website"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
