import React from 'react'
import asanaIconWhite from '../../images/asana-icon-white.svg'
import { SignupBar } from './SignupBar'

export const AsanaSignupBar: React.FC = () => (
  <SignupBar
    title="Asana"
    description="Sign up and connect in under a minute"
    iconSrc={asanaIconWhite}
    url={process.env.SIGNUP_URL}
  />
)
