import React from 'react'
import githubIconWhite from '../../images/github-white.svg'
import { SignupBar } from './SignupBar'

export const GithubSignupBar: React.FC = () => (
  <SignupBar
    title="GitHub"
    description="Sign up for Qualdesk using your GitHub credentials"
    iconSrc={githubIconWhite}
    url={process.env.AUTH_GITHUB_URL}
  />
)
