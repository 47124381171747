import React from 'react'
import { Lightning } from 'phosphor-react'

interface SignupBarProps {
  title: string
  description: string
  iconSrc: string
  url: string | undefined
}

export const SignupBar: React.FC<SignupBarProps> = ({
  title,
  description,
  iconSrc,
  url,
}) => (
  <section className="bg-background-five-dark my-4 p-8 text-center rounded-xl sm:my-8 md:flex md:items-center md:py-6 md:text-left md:space-x-4">
    <div className="flex-grow md:flex md:items-center md:space-x-4">
      <img
        className="mx-auto w-12 h-12 md:mx-0"
        src={iconSrc}
        alt={`${title} icon`}
      />
      <div className="mt-4 md:mt-0">
        <div className="text-text-primary-dark text-xl font-medium tracking-tight leading-tight">
          Connect {title}
        </div>
        <p className="text-text-secondary-dark mt-2 text-base md:mt-0 md:text-sm">
          {description}
        </p>
      </div>
    </div>
    <a
      className="group bg-background-one-dark rounded-[20px] flex flex-shrink-0 items-center mt-6 mx-auto p-1 w-max cursor-pointer md:mt-0"
      href={url}
    >
      <div className="to-background-five-dark from-background-four-dark group-hover:from-teal-900 group-hover:to-teal-800 rounded-[16px] w-[90px] h-[48px] flex items-center justify-center bg-gradient-to-t">
        <Lightning
          className="text-icon-primary-dark group-hover:text-teal-400 w-6 h-6"
          weight="fill"
        />
      </div>
      <div className="text-text-secondary-dark w-[90px] h-[48px] flex items-center justify-center text-base font-medium tracking-tight leading-tight">
        Connect
      </div>
    </a>
  </section>
)
